import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'gatsby'
import {
  Section,
  Container,
  Columns,
  Hero,
  Box,
  Card,
} from 'react-bulma-components'

// Components
import SiteNav from 'components/navbar'
import Layout from 'components/layout'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { GatsbySeo, ProductJsonLd } from 'gatsby-plugin-next-seo'

// Page Components

function Package({ data }) {
  return (
    <>
      <GatsbySeo
        title={`${data.package.name} Service`}
        description={data.package.description}
      />
      <Layout>
        <Hero className="is-small pb-6">
          <Hero.Header>
            <SiteNav fullLogo />
          </Hero.Header>
          <Hero.Body className="pt-6 mt-6 ">
            <Container className="content">
              <Columns className="is-vcentered">
                <Columns.Column className="is-7">
                  <h1 className="subtitle is-size-5 is-uppercase mb-2 pb-0">
                    {data.package.name} Service
                  </h1>
                  <h2
                    className="title is-size-2 has-text-weight-bold mt-0 is-landing-text"
                    dangerouslySetInnerHTML={{
                      __html:
                        data.package.straplineNode.childMarkdownRemark.html,
                    }}
                  />
                  <ProductJsonLd
                    name={`${data.package.name} Service`}
                    description={data.package.description}
                    brand="Pocketworks"
                    image={[data.package.heroImage.url]}
                    offers={{
                      '@type': 'Offer',
                      price: data.package.price,
                      priceCurrency: 'GBP',
                    }}
                  />
                  <p>
                    {data.package.price ? (
                      <span className="is-size-4 has-text-primary has-text-weight-bold">
                        &pound;
                        {new Intl.NumberFormat().format(data.package.price)} +
                        VAT
                      </span>
                    ) : (
                      'FREE'
                    )}
                  </p>
                  <div
                    className="is-size-5 mb-5"
                    dangerouslySetInnerHTML={{
                      __html: data.package.introNode.childMarkdownRemark.html,
                    }}
                  />
                  {data.package.payhipId ? (
                    <div>
                      <Link
                        className="button is-medium is-leafy"
                        target="_blank"
                        to={`https://payhip.com/buy?link=${data.package.payhipId}`}
                      >
                        Purchase Now
                      </Link>
                      <Link to="/contact" className="button is-text is-medium">
                        Arrange a consultation
                      </Link>
                    </div>
                  ) : (
                    <Link to="/contact" className="button is-leafy is-medium">
                      Arrange a consultation
                    </Link>
                  )}
                </Columns.Column>
                <Columns.Column className="is-4 is-offset-1">
                  <GatsbyImage
                    image={data.package.heroImage.gatsbyImageData}
                    alt={data.package.name || 'Pocketworks'}
                  />
                </Columns.Column>
              </Columns>
            </Container>
          </Hero.Body>
        </Hero>

        <Section
          className="is-hidden is-medium has-bg-image blog-hero has-shadow-top-bottom w-full"
          style={{
            backgroundImage: data.package.contextPhoto
              ? `url(${data.package.contextPhoto.url}?fm=jpg&w=1800&duotone=f99f53,FA8072&duotone-alpha=6)`
              : '',
          }}
        >
          <Columns>
            <Columns.Column className="is-6">
              <Box className="has-background-grey-darker">
                <h3
                  className="subtitle is-size-3-desktop is-size-4-mobile has-text-light p-5"
                  dangerouslySetInnerHTML={{
                    __html: data.package.introNode.childMarkdownRemark.html,
                  }}
                />
              </Box>
            </Columns.Column>
          </Columns>
        </Section>
        {data.package.contentBlocks.map((block) => (
          <>
            {block.hasTopSeparator ? (
              <Section
                className={`${
                  block.backgroundClass === 'is-dark-bg'
                    ? 'has-background-grey-darker'
                    : ''
                } p-0 m-0 is-small w-full`}
              >
                <div
                  className={`${
                    block.backgroundClass === 'is-dark-bg'
                      ? 'has-background-white-ter'
                      : 'has-background-grey-darker'
                  } is-rounded-separator`}
                />
              </Section>
            ) : null}
            <Section
              key={block.id}
              className={`${block.backgroundClass} content mb-0`}
            >
              <Container className="content mt-6">
                <Columns>
                  <Columns.Column className="is-6">
                    <h2 className="title">{block.title}</h2>
                    <div
                      className="content"
                      dangerouslySetInnerHTML={{
                        __html: block.bodyNode.childMarkdownRemark.html,
                      }}
                    />
                    <Link
                      to="/contact"
                      className="button mt-6 is-medium is-leafy is-primary"
                    >
                      Arrange a consultation
                    </Link>
                  </Columns.Column>
                  <Columns.Column className="pl-6 is-6">
                    <GatsbyImage
                      className="is-rounded pl-6"
                      image={block.image ? block.image.gatsbyImageData : null}
                      alt={block.title || 'Pocketworks'}
                    />
                  </Columns.Column>
                </Columns>
              </Container>
            </Section>
            {block.hasBottomSeparator ? (
              <Section
                className={`${
                  block.backgroundClass === 'is-dark-bg'
                    ? ''
                    : 'has-background-grey-darker'
                } p-0 m-0 is-small w-full`}
              >
                <div
                  className={`${
                    block.backgroundClass === 'is-dark-bg'
                      ? 'has-background-grey-darker'
                      : ''
                  } is-rounded-separator`}
                />
              </Section>
            ) : null}
          </>
        ))}
      </Layout>
    </>
  )
}
Package.propTypes = {
  data: PropTypes.object,
}
export default Package
